<template>
  <div class="product-sell-card-container">
    <b-card class="product-card-badge">
      <div class="product-box">
        <div class="product-img">
          <!--  <b-row align="center" class="mb-2 no-gutters container-badge">
             <b-col cols="6" class="badge-container">
              <div class="badge-green-light">
                 {{ $t('views.shop.product-card.sold') }}
               </div>
            </b-col>
            <b-col cols="6" class="badge-container">
             <div class="badge-red-light">
                  {{ stock }} {{ $t('views.shop.product-card.stock') }}
                </div>
              </b-col>
            </b-row> -->
            <div class="img-container">
              <img
                v-if="product.photos.length === 0"
                class="img-fluid"
                src="@/assets/images/playground/ban.svg"
                alt
              />
              <img
                v-else
                :src="$filePath(product.photos[0].contentUrl)"
                class="mt-2 img-fluid object-fit-img"
                alt
              />
            </div>
          </div>
          <div class="mt-2 border-bottom-grey">
          </div>
          <div class="ml-2 mr-2">
            <div class="row mt-4">
              <b-col
                align="right"
                cols="8"
                class="title-product"
              >
                {{ product.name }}
              </b-col>
              <b-col
                align="right"
                class="title-product-price"
              >
                {{ product.unitaryPrice / 100 | currency('') }} {{ $currency }}
              </b-col>
            </div>

            <div class="mt-2" v-if="from !== 'customer'">
              <p class="description-product pt-2">
                {{ product.shortDescription }}
              </p>
            </div>
            <b-row class="mt-2" v-if="from === 'customer'">
              <b-col class="mb-1 mt-1 pr-0" col="12">
                <d-button
                  :text="'general.actions.sell-product'"
                  :class="'d-btn-custom btn d-btn-primary font-text-title w-100'"
                  iconSize="15"
                  icon="fa fa-trash mr-1"
                  @on:button-click="sellProduct(product)"
                />
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="from !== 'customer'">
              <b-col class="mb-1 mt-1 pr-0" col="6">
                <d-button
                  :text="'general.actions.delete'"
                  :class="'d-btn-custom btn d-btn-danger font-text-title'"
                  iconSize="15"
                  icon="fa fa-trash mr-1"
                  @on:button-click="deleteProduct(product.id)"
                />
              </b-col>
              <b-col class="mb-1 mt-1 pl-0"  col="6" align="right">
                <d-button
                  :text="'general.actions.edit'"
                  :class="'d-btn-custom btn d-btn-primary font-text-title'"
                  iconSize="15"
                  icon="icofont icofont-ui-edit mr-1"
                  @on:button-click="editProduct(product.id)"
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </template>
  <script>
  import {deleteProduct} from "@api/doinsport/services/products/products.api";
  import Product from "@/classes/nextore/Product";

  export default {
    props: {
      customer: {
        type: Object,
        default: null
      },
      from: {
        type: String,
        default: ''
      },
      product: {
        type: Object,
        default: () => {
        }
      }
    },
    computed: {
      stock() {
        if (this.product.stock === null) {
          return "0"
        } else {
          return this.product.stock
        }
      }
    },
    methods: {
      sellProduct(prod) {
        console.log('show me product : ', prod);

        const club = this.$store.getters['auth/currentClub'];
        if (club.nextoreAccount) {
          const product = new Product();
          product.id = prod.nextoreProductReference ? prod.nextoreProductReference : null;
          product.name = prod.name;
          product.price = parseFloat('' + (prod.unitaryPrice / 100));
          product.quantity = 1;
          this.$store.dispatch('nextore/setCustomer', this.customer);
          this.$store.dispatch('nextore/addProduct', product);

          this.$nextTick(() => {
            setTimeout(() => {
              this.display = true;
            }, 50)
          });
        }

      },
      editProduct(id) {
        this.$router.push({ name: 'update_product', params: { id: id }})
      },
      deleteProduct(id) {
        if (confirm('' + this.$t('views.shop.confirm-delete-product'))) {
          deleteProduct(id);
          this.$emit('on:reload-products', id)
        }
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  @import "@lazy/shop/_product-card.scss";
  </style>
